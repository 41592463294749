
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ISlices.IUserInitialState = {
  userInfo: null,
  userLoading: false,
  paymentModal: false,
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo(state: any, action: PayloadAction<IUtils.IGetUserData>) {
      state.userInfo = action.payload;
    },
    setUserLoading(state: { userLoading: boolean }, action: PayloadAction<boolean>) {
      state.userLoading = action.payload;
    },
    setPaymentModal(state: { paymentModal: boolean }, action: PayloadAction<boolean>) {
      state.paymentModal = action.payload;
    },
  },
});


export const { setUserInfo, setUserLoading, setPaymentModal } = userInfoSlice.actions
export default userInfoSlice.reducer;
